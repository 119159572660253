export function raffle_timer_time_left_for_raffle(raffle) {
	
	raffle.seconds--;
	
	if(raffle.seconds < 0) {
		
		raffle.seconds = 59;
		
		raffle_timer_decrease_minutes(raffle);
	}
	
	var component = this;
	
	setTimeout(function() { raffle_timer_time_left_for_raffle(raffle); }, 1000);
}
	
function raffle_timer_decrease_minutes(raffle) {
		
	raffle.minutes--;
	
	if(raffle.minutes < 0) {
		
		raffle.minutes = 59;
		
		raffle_timer_decrease_hours(raffle);
	}
}
	
function raffle_timer_decrease_hours(raffle) {
		
	raffle.hours--;
	
	if(raffle.hours < 0) {
		
		raffle.hours = 23;
		
		raffle_timer_decrease_days(raffle);
	}
}
	
function raffle_timer_decrease_days(raffle) {
	
	raffle.days--;
	
	if(raffle.days < 0) {
		
		raffle.time_left = 'closed';
	}
}

