<template>
  <a class="raffle-card-background" :href="'/raffle/' + raffle.id">
    <div class="raffle-card">
      <div class="raffle-img">
        <img :src="raffle.image"/>
      </div>
      <span class="raffle-title" style="min-height: 60px;display: flex;flex-direction: column;justify-content: space-around;">{{ raffle.name }}</span>
      <div class="raffle-line">
      </div>
      <div class="raffle-info">
        <div class="raffle-sold">
          <span>
            Sold:
          </span>
			<span v-if="raffle.max_tickets > 0">
				{{ raffle.tickets_sold }} / {{ raffle.max_tickets }}
			</span>
			<span v-else>
				{{ raffle.tickets_sold }}
			</span>
        </div>
        <div class="raffle-winners">
          <span>
            Winners:
          </span>
          <span>
            {{ raffle.winners }}
          </span>
        </div>
      </div>
      <div class="raffle-time-left" v-if="raffle.time_left != 'closed'">
        <span>
          Ends in:
        </span>
        <span>
			{{ raffle.days }}d 
			{{ raffle.hours }}h 
			{{ raffle.minutes }}m 
			{{ raffle.seconds }}s 
        </span>
      </div>
      <div class="raffle-time-left" v-else>
        <span>
          Raffle Closed
        </span>
      </div>

		<a :class="{'raffle-button button' : true , 'open' : raffle.time_left != 'closed'}" :href="'/raffle/' + raffle.id">
        <div class="button-left-border"></div>
        <div class="button-right-border"></div>
        <div class="button-content">
          <span v-if="raffle.time_left != 'closed'">JOIN RAFFLE</span>
          <span v-else>see winners</span>
        </div>
      </a>
    </div>
  </a>
</template>

<script>

import {raffle_timer_time_left_for_raffle} from '@/libs/raffleTimer';

export default {
  name: 'RaffleCard',
  props : {
    time_left : {
      type: String,
      default: '00d 12h 45m 10s'
    },
    raffle_id : {
      type: Number,
      default: 1
    },
	raffle: {}
  },
  methods: {
	
	
  },
  mounted: function() {
	
	raffle_timer_time_left_for_raffle(this.raffle);
  }
}
</script>

<style scoped>

</style>