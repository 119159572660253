<template>
  <div class="raffles-list">
    <RaffleCard v-for="(raffle, key) in raffles" :key="key" :raffle="raffle"></RaffleCard>
   
  </div>
</template>

<script>
import RaffleCard from '../components/RaffleCard.vue';

import axios from 'axios';

let config_axios = {
	headers: {
		'Content-Type': 'application/json;'
	}
};

export default {
  name: 'Raffles',
  components : {
    RaffleCard,
  },
  data: function () {

		return {
			
			raffles: []
		}
	},
	mounted: function(){
	
		var component = this;
	
		// get live raffle
		axios.get('https://marketplace.bluediamonds.app/cct/get_old_raffles').then(function (result) {
			
			component.raffles = result.data.raffles;
		});
	
	},
}
</script>

<style scoped>

</style>